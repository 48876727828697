import { createAction, props } from '@ngrx/store';
import { StatusState } from '@store/models/status.model';

export enum StatusActions {
    SET_SYSTEM_HAS_ERRORS_INIT = 'SET_SYSTEM_HAS_ERRORS_INIT',
    SET_SYSTEM_HAS_ERRORS_SUCCESS = 'SET_SYSTEM_HAS_ERRORS_SUCCESS',
    SET_SYSTEM_HAS_ERRORS_FAILED = 'SET_SYSTEM_HAS_ERRORS_FAILED',
}

export const SetSystemHasErrorsInitAction = createAction(
    StatusActions.SET_SYSTEM_HAS_ERRORS_INIT,
    props<{ payload: StatusState }>()
);

export const SetSystemHasErrorsSuccessAction = createAction(
    StatusActions.SET_SYSTEM_HAS_ERRORS_SUCCESS
);

export const SetSystemHasErrorsFailedAction = createAction(
    StatusActions.SET_SYSTEM_HAS_ERRORS_FAILED
);
