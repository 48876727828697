import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    /**
     * @deprecated - never expose subjects
     */
    public showLoader$: Subject<boolean> = new BehaviorSubject(false);

    public loaderState$ = this.showLoader$.asObservable();

    constructor(private readonly router: Router) {
        this.router.events
            .pipe(
                filter((x) => x instanceof NavigationEnd),
                tap(() => this.showLoader$.next(false))
            )
            .subscribe();
    }

    public showLoader(state: boolean): void {
        this.showLoader$.next(state);
    }
}
