import { Component, EventEmitter, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-mutation-notification',
    templateUrl: './mutation-notification.component.html',
})
export class MutationNotificationComponent {
    @Output() saveEvent = new EventEmitter<void>();
    @Output() discardEvent = new EventEmitter<void>();

    constructor(private readonly snackBar: MatSnackBar) {}

    public saveChanges(): void {
        this.saveEvent.emit();
        this.snackBar.dismiss();
    }

    public discardChanges(): void {
        this.discardEvent.emit();
        this.snackBar.dismiss();
    }
}
