<div class="login_screen_holder" autocomplete="new-password">
    <mat-tab-group
        (selectedTabChange)="handleTabChange($event)"
        [selectedIndex]="getSelectedIndex() | async"
        class="login-form"
    >
        <mat-tab
            *ngFor="let loginComponent of loginComponents; let i = index"
            [label]="loginComponent"
        >
            <ng-template matTabContent>
                <section class="animated fadeIn">
                    <mat-card>
                        <div class="form-wrapper">
                            <div class="login-image--small">
                                <img src="img/omnia_logo.png" />
                            </div>
                            <mat-spinner diameter="40" *ngIf="showLoader$ | async; else login">
                            </mat-spinner>
                            <ng-template #login>
                                <app-forgot-password
                                    *ngIf="i === authForms.FORGOTPASSWORD"
                                ></app-forgot-password>

                                <app-forgot-password-update
                                    *ngIf="i === authForms.UPDATEPASSWORD"
                                ></app-forgot-password-update>

                                <app-register-new-user
                                    *ngIf="i === authForms.REGISTER"
                                ></app-register-new-user>
                            </ng-template>
                        </div>
                    </mat-card>
                </section>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
