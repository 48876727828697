import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { LoaderService } from '@services/loader/loader.service';
import { LoginService } from '@services/login/login.service';

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
    public readonly showLoader$ = this.loaderService.loaderState$;
    public readonly loginComponents = this.loginService.loginComponents;
    public readonly authForms = this.loginService.authForms;

    constructor(
        private readonly loginService: LoginService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly loaderService: LoaderService
    ) {}

    ngOnInit(): void {
        const { code } = this.activatedRoute.snapshot.params;
        const { path } = this.activatedRoute.snapshot.routeConfig;

        if (path.includes('requestpassword')) {
            this.loginService.updateTabIndex(this.authForms.FORGOTPASSWORD);
        }

        if (code && path.includes('forgotpassword')) {
            this.loginService.updateTabIndex(this.authForms.UPDATEPASSWORD);
        }

        // Register new user
        if (code && path.includes('register')) {
            this.loginService.updateTabIndex(this.authForms.REGISTER);
        }
    }

    public handleTabChange({ index }: MatTabChangeEvent): void {
        this.loginService.updateTabIndex(index);
    }

    public getSelectedIndex(): Observable<number> {
        return this.loginService.getCurrentIndex();
    }
}
