import { Datacenter } from '@datadog/browser-rum';

export const environment = {
    production: true,
    settings: 'test',
    googleId: '512255490470-8ojh7mnhubb7elcr0ik59k6m2ekb1vre.apps.googleusercontent.com',
    bffEndpointV2: 'https://frontendapi.test.omniaretail.internal/api',
    bffEndpoint: 'https://frontendapi.test.omniaretail.internal/legacy',
    bffAbsEndpoint: 'https://frontendapi.test.omniaretail.internal',
    statusIOEndpoint: 'https://dm8lxbqw4p59.statuspage.io/api/v2/summary.json',
    isSecure: false,
    isProduction: false,
    isIridizeEnabled: true,
    logging: {
        applicationId: '50ad2eb1-42aa-48ce-9a3c-7938d67ce39c',
        clientToken: 'pub108ed29256887ef499b3cf77d6c66076',
        datacenter: Datacenter.US,
        sampleRate: 100,
        service: 'portal_test',
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
    },
    features: {
        // Overridden by Octopus
        looker: {
            domain: 'https://omniaretail.cloud.looker.com',
            dashboardId: 1,
        },
        singleSignOn: {
            properties: {
                domain: 'omniaretail-test.eu.auth0.com',
                clientId: 'mnT8sM5zA1wXLGVYgqfLOWaRbRAKW1Qi',
                audience: 'omniaretail.com',
                redirectUri: 'https://portal.test.omniaretail.internal/login',
                httpInterceptor: {
                    allowedList: [`//portal.test.omniaretail.internal/*`],
                },
            },
        },
    },
};
