import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '@services/authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationGuardService implements CanActivate {
    constructor(private readonly authenticationService: AuthenticationService) {}

    public canActivate(): Observable<boolean> {
        return this.authenticationService.isAuthenticated().pipe(
            tap((isLoggedIn: boolean) => {
                if (!isLoggedIn) {
                    this.authenticationService.accessVendorLogin();
                } else {
                    this.authenticationService.renewToken();
                }
            })
        );
    }
}
