import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, tap, catchError, filter, take, map, exhaustMap } from 'rxjs/operators';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { BrowserStorageService } from '@services/common/localstorage.service';
import { OrganizationsPortalInterface } from '@models/interfaces/portal.model.interface';
import { PortalService } from '@services/portal/portal.service';
import {
    AuthenticationActions,
    SetIsSsoInitActionSuccess,
    UserLoggedOutSuccessAction,
} from '@store/actions/authentication.actions';
import { OrganizationSetInitAction } from '@store/actions/organization.actions';
import { selectUserEmail } from '@store/selectors/authentication.selectors';
import { ToastService } from '@services/toast/toast.service';
import { AppState } from '@store/models/app.model';
import { AuthService } from '@auth0/auth0-angular';
import { selectLastVisitedOrganizationAndPortal } from '@store/selectors/preferences-selectors';
import { UserPreferencesState } from '@store/models/preferences.model';
import { SetUserPreferencesInitAction } from '@store/actions/preferences.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from '@services/logging/logging.service';
import { filterNullAndUndefined } from '@utils/helpers/is-defined';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ResponseToken } from '../models/authentication.model';

@Injectable()
export class AuthenticationEffectsService {
    public readonly login$ = createEffect(() => this.loginSideEffect(), {
        dispatch: false,
    });

    public readonly renewToken$ = createEffect(() => this.renewTokenSideEffect(), {
        dispatch: false,
    });

    public readonly logout$ = createEffect(() => this.logoutSideEffect(), {
        dispatch: false,
    });

    constructor(
        private readonly browserStorageService: BrowserStorageService,
        private readonly portalService: PortalService,
        private readonly auth: AuthService,
        private readonly store: Store<AppState>,
        private readonly loggingService: LoggingService,
        private readonly toastService: ToastService,
        private readonly authenticationService: AuthenticationService,
        private readonly actions: Actions,
        private readonly router: Router
    ) {}

    private loginSideEffect(): Observable<UserPreferencesState> {
        return this.actions.pipe(
            ofType(AuthenticationActions.USER_LOGIN_SUCCESS),
            exhaustMap(() => this.getAndSetUserName()),
            exhaustMap(() => this.getAndSetUserToken()),
            exhaustMap(() => this.setOrganizationPortals()),
            exhaustMap((orgPortals) => this.setSavedState(orgPortals)),
            tap((defaultPortal) => {
                if (defaultPortal.lastVisitedPortal) {
                    this.setSelectedPortal(defaultPortal);
                    return;
                }

                void this.router.navigateByUrl('login-error');
            })
        );
    }

    private renewTokenSideEffect() {
        return this.actions.pipe(
            ofType(AuthenticationActions.RENEW_TOKEN_INIT),
            switchMap(() => this.getAndSetUserToken())
        );
    }

    private logoutSideEffect(): Observable<string | boolean> {
        return this.actions.pipe(
            ofType(AuthenticationActions.USER_LOGOUT_INIT),
            tap(() => {
                this.authenticationService.clearLocalStorage();
                this.auth.logout({
                    returnTo: window.location.origin + '/login',
                });
                this.store.dispatch(UserLoggedOutSuccessAction());
            })
        );
    }

    private getAndSetUserName(): Observable<string> {
        return this.store.pipe(
            select(selectUserEmail),
            tap((email) => {
                // Some Lingering legacy components still read from the localstorage
                this.browserStorageService.setItem('omnia-username', email);
            })
        );
    }

    private setOrganizationPortals(): Observable<OrganizationsPortalInterface | HttpErrorResponse> {
        return this.portalService.getOrganizationAndPortals().pipe(
            tap((orgPortals) => {
                const entries = Object.entries(orgPortals);
                this.store.dispatch(
                    OrganizationSetInitAction({
                        authorization: entries,
                    })
                );
            }),
            catchError((error: HttpErrorResponse) => {
                if (error?.status === 404) {
                    this.loggingService.addUserAction('catch error', {
                        component: this.constructor.name,
                        methodName: 'setOrganizationPortals',
                        Exception: { ...error, username: localStorage.getItem('omnia-username') },
                    });
                }

                return of(error);
            })
        );
    }

    private setSavedState(orgPortals): Observable<UserPreferencesState> {
        return this.store.select(selectLastVisitedOrganizationAndPortal).pipe(
            filter(filterNullAndUndefined),
            take(1),
            map((userPreference) => {
                const defaultOrgName = Object.keys(orgPortals)[0];
                const defaultPortalName =
                    orgPortals[defaultOrgName] && orgPortals[defaultOrgName][0];

                const userHasSavedPortal =
                    userPreference?.lastVisitedPortal && userPreference?.lastVisitedOrganization;

                const hasSavedInCollection =
                    userHasSavedPortal &&
                    orgPortals[userPreference?.lastVisitedOrganization]?.includes(
                        userPreference?.lastVisitedPortal
                    );

                const lastVisitedPortal = hasSavedInCollection
                    ? userPreference?.lastVisitedPortal
                    : defaultPortalName;

                const lastVisitedOrganization = hasSavedInCollection
                    ? userPreference?.lastVisitedOrganization
                    : defaultOrgName;

                if (lastVisitedPortal && lastVisitedOrganization) {
                    this.browserStorageService.setItem('organizationName', lastVisitedOrganization);
                    this.browserStorageService.setItem('omnia-portal', lastVisitedPortal);
                }

                return {
                    lastVisitedPortal,
                    lastVisitedOrganization,
                };
            })
        );
    }

    private setSelectedPortal({
        lastVisitedPortal,
        lastVisitedOrganization,
    }: UserPreferencesState): void {
        if (!lastVisitedPortal || !lastVisitedOrganization) {
            this.toastService.notifyError('No portal found on your account.');
            return;
        }

        return this.store.dispatch(
            SetUserPreferencesInitAction({
                payload: {
                    lastVisitedPortal,
                    lastVisitedOrganization,
                    lastLoggedInUser: localStorage.getItem('omnia-username'),
                },
            })
        );
    }

    private getAndSetUserToken(): Observable<string> {
        return this.authenticationService.getUserToken().pipe(
            filter((token) => !!token),
            tap((token) => {
                this.store.dispatch(
                    SetIsSsoInitActionSuccess({
                        payload: { isSingleSignOnEnabled: this.hasSingleSignOnToken(token) },
                    })
                );
                this.browserStorageService.setItem('Token', token);
            })
        );
    }

    private hasSingleSignOnToken(token: string): boolean {
        const helper = new JwtHelperService();
        const isSso: ResponseToken = helper.decodeToken(token);
        return isSso['http://omniaretail.com/isSso'];
    }
}
