// Remove login once deprecated
export enum AuthForms {
    LOGIN,
    FORGOTPASSWORD,
    UPDATEPASSWORD,
    REGISTER,
}
export enum SsoResponseEnum {
    SsoUserError = 'SsoUserError'
}
