import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from '@services/guards/authentication-guard.service';
import { AppContainerModule } from '@features/app-container/app-container.module';
import { AuthenticationComponent } from '@features/authentication/authentication/authentication.component';
import { ErrorComponent } from '@components/error/error.component';

export const routes: Routes = [
    // Keeping this route as some customers might have bookmarked it
    {
        path: 'login',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    { path: 'requestpassword', component: AuthenticationComponent },
    {
        path: 'forgotpassword/:code',
        component: AuthenticationComponent,
    },
    { path: 'register/:code', component: AuthenticationComponent },
    { path: 'login-error', component: ErrorComponent },
    {
        path: '',
        loadChildren: (): Promise<AppContainerModule> =>
            import('@features/app-container/app-container.module').then(
                ({ AppContainerModule }) => AppContainerModule
            ),
        canActivate: [AuthenticationGuardService],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
