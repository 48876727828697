import { createAction, props } from '@ngrx/store';
import { UserPreferencesState } from '@store/models/preferences.model';

export enum UserPreferencesActions {
    // Preferences actions
    SET_USER_PRFERENCES_INIT = 'SET_USER_PRFERENCES_INIT',
    SET_USER_PREFERENCES_SUCCESS = 'SET_USER_PREFERENCES_SUCCESS',
    SET_USER_PREFERENCES_FAILED = 'SET_USER_PREFERENCES_FAILED',

    // Logo actions
    SET_PORTAL_LOGO_INIT = 'SET_PORTAL_LOGO_INIT',
    SET_PORTAL_LOGO_SUCCESS = 'SET_PORTAL_LOGO_SUCCESS',
    SET_PORTAL_LOGO_FAILED = 'SET_PORTAL_LOGO_FAILED',
}

export const SetUserPreferencesFailedAction = createAction(
    UserPreferencesActions.SET_USER_PREFERENCES_FAILED
);

export const SetPortalLogoFailedAction = createAction(
    UserPreferencesActions.SET_PORTAL_LOGO_FAILED
);

export const SetPortalLogoInitAction = createAction(UserPreferencesActions.SET_PORTAL_LOGO_INIT);

export const SetPortalLogoSuccessAction = createAction(
    UserPreferencesActions.SET_PORTAL_LOGO_SUCCESS,
    props<{ payload: UserPreferencesState }>()
);

export const SetUserPreferencesInitAction = createAction(
    UserPreferencesActions.SET_USER_PRFERENCES_INIT,
    props<{ payload: UserPreferencesState }>()
);

export const SetUserPreferencesSuccessAction = createAction(
    UserPreferencesActions.SET_USER_PREFERENCES_SUCCESS,
    props<{ payload: UserPreferencesState }>()
);
