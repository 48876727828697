import { Component } from '@angular/core';
import { SecurityService } from '../../services/security/security.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
    constructor(private readonly securityService: SecurityService) {
        setTimeout(() => {
            this.securityService.logout();
        }, 4000);
    }
}
