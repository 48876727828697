import { createSelector } from '@ngrx/store';
import { AppState } from '@store/models/app.model';
import { UserPreferencesState } from '@store/models/preferences.model';

const selectPreferencesFeature = ({ container }: AppState) => container?.userPreferences;

export const selectLastVisitedOrganizationAndPortal = createSelector(
    selectPreferencesFeature,
    (userPreferences: UserPreferencesState) => userPreferences
);

export const selectlastLoggedInUser = createSelector(
    selectPreferencesFeature,
    (userPreferences: UserPreferencesState) => userPreferences?.lastLoggedInUser
);

export const selectLastVisitedOrganization = createSelector(
    selectPreferencesFeature,
    ({ lastVisitedOrganization }: UserPreferencesState) => lastVisitedOrganization
);

export const selectLastVisitedPortal = createSelector(
    selectPreferencesFeature,
    ({ lastVisitedPortal }: UserPreferencesState) => lastVisitedPortal
);
