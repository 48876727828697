import { Directive, ElementRef, isDevMode } from '@angular/core';

@Directive({
    selector: '[appIsDev]',
})
export class IsDevDirective {
    constructor(private element: ElementRef) {
        if (isDevMode && this.element.nativeElement) {
            this.element.nativeElement?.setAttribute('data-isdev-only', 'true');
        }
    }
}
