import { createAction, props } from '@ngrx/store';
import { AuthState } from '@store/models/authentication.model';

export enum AuthenticationActions {
    // Login actions
    USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILED = 'USER_LOGIN_FAILED',

    // Logout actions
    USER_LOGOUT_INIT = 'USER_LOGOUT_INIT',
    USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS',
    USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED',

    // Permission actions
    GET_PERMISSION_INIT = 'GET_PERMISSION_INIT',
    GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS',
    GET_PERMISSION_FAILED = 'GET_PERMISSION_FAILED',

    // Renew token actions
    RENEW_TOKEN_INIT = 'RENEW_TOKEN_INIT',

    // SSO actions
    SET_IS_SSO_SUCCESS = 'SET_IS_SSO_SUCCESS',
}

export const UserLoginSuccessAction = createAction(
    AuthenticationActions.USER_LOGIN_SUCCESS,
    props<{ payload: AuthState }>()
);

export const UserLoginFailedAction = createAction(
    AuthenticationActions.USER_LOGIN_FAILED,
    props<{ payload: AuthState }>()
);

export const UserLoggedOutInitAction = createAction(AuthenticationActions.USER_LOGOUT_INIT);
export const UserLoggedOutSuccessAction = createAction(AuthenticationActions.USER_LOGOUT_SUCCESS);
export const UserLoggedOutFailedAction = createAction(AuthenticationActions.USER_LOGOUT_FAILED);
export const RenewTokenInitAction = createAction(AuthenticationActions.RENEW_TOKEN_INIT);

export const UserAssignPermissionsInitAction = createAction(
    AuthenticationActions.GET_PERMISSION_INIT
);

export const UserAssignPermissionsSuccessAction = createAction(
    AuthenticationActions.GET_PERMISSION_SUCCESS
);

export const UserAssignPermissionsFailedAction = createAction(
    AuthenticationActions.GET_PERMISSION_FAILED
);

export const SetIsSsoInitActionSuccess = createAction(
    AuthenticationActions.SET_IS_SSO_SUCCESS,
    props<{ payload: AuthState }>()
);
