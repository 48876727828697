import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LogoModel } from '@models/logo.model';
import { UserRightModel } from '@models/user-right.model';
import { BrowserStorageService } from '@services/common/localstorage.service';
import { AuthService } from '@auth0/auth0-angular';
import { take, delay, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Permissions } from '@models/enum/permissions';
import { Store } from '@ngrx/store';
import { SuperUser } from '@models/user.model';
import { AppState } from '@store/models/app.model';
import { RenewTokenInitAction } from '@store/actions/authentication.actions';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(
        private readonly browserStorageService: BrowserStorageService,
        private readonly auth: AuthService,
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly http: HttpClient
    ) {}

    public getLogo(): Observable<LogoModel> {
        return this.http.get<LogoModel>(environment.bffAbsEndpoint + '/user/logo');
    }

    public getLoggedInUser(): Observable<import('@auth0/auth0-spa-js').User> {
        return this.auth.user$;
    }

    public getUserToken(): Observable<string> {
        return this.auth.getAccessTokenSilently();
    }

    // TODO kill me with fire
    public setCredentials(response: UserRightModel): void {
        const permissions: any = {};
        // Should have default value as
        const isOrgAdmin = response.permissions.find(
            (permission) => permission === SuperUser.OrganizationAdmin
        );

        localStorage.setItem('omnia-orgadmin', isOrgAdmin ? 'true' : 'false');

        response.permissions.forEach((element) => {
            if (element.toLowerCase().indexOf('readwrite') > -1) {
                element = element.replace('ReadWrite', '');
                permissions[element] = 'ReadWrite';
            }

            if (element.toLowerCase().indexOf('read') > -1) {
                element = element.replace('Read', '');
                permissions[element] = 'Read';
            }
        });

        if (!('Reports' in permissions) && 'Marketing' in permissions) {
            permissions['Reports'] = permissions['Marketing'];
        }

        if (!('ProductOverview' in permissions) && !('PricewatchExport' in permissions)) {
            if ('Pricing' in permissions) {
                permissions['PricewatchExport'] = permissions['Pricing'];
                permissions['ProductOverview'] = permissions['Pricing'];
            }
        }

        let licenses = [];
        if (response.licenses) {
            licenses = response.licenses.map((l) => l.toLowerCase());
        }

        if (
            licenses.find((x) => x.toString().toLowerCase() === 'adps') &&
            'Pricing' in permissions
        ) {
            permissions['PricingStrategy'] = permissions['Pricing'];
        }

        localStorage.setItem('omnia-lic', licenses ? JSON.stringify(licenses) : '');

        localStorage.setItem('omnia-rights', permissions ? JSON.stringify(permissions) : '');
    }

    public accessVendorLogin(): void {
        this.auth.loginWithRedirect().pipe(take(1)).subscribe();
    }

    public accessApplication(): void {
        void this.router.navigateByUrl('dashboard');
    }

    // Legacy
    public clearLocalStorage(): void {
        window.sessionStorage.removeItem('shopid');
        window.sessionStorage.removeItem('groupid');
        window.sessionStorage.removeItem('groupdisplayname');
        this.browserStorageService.removeItem('omnia-currency');
        this.browserStorageService.removeItem('orgPortals');
        this.browserStorageService.removeItem('omnia-username');
        this.browserStorageService.removeItem('organizationName');
        this.browserStorageService.removeItem('omnia-superadmin');
        this.browserStorageService.removeItem('omnia-orgadmin');
        this.browserStorageService.removeItem('omnia-authdata');
        this.browserStorageService.removeItem('omnia-selected-shop-for-strategy');
        this.browserStorageService.removeItem('omnia-domain');
        this.browserStorageService.removeItem('omnia-lic');
        this.browserStorageService.removeItem('Token');
        this.browserStorageService.removeItem('omnia-rights');
        this.browserStorageService.removeItem('omnia-portal');
        this.browserStorageService.removeItem('omnia-portals');
        this.browserStorageService.removeItem('omnia-flags');
        this.browserStorageService.removeItem('omnia-product-toDate');
        this.browserStorageService.removeItem('omnia-product-fromDate');
    }

    public hasLicense(license: string): boolean {
        if (localStorage.getItem('omnia-lic')) {
            return localStorage.getItem('omnia-lic').indexOf(license) !== -1;
        }

        return false;
    }

    // TODO: Rewrite this (legacy)
    public isAuthorised(item: string): boolean {
        if (item === Permissions.status || item === Permissions.dashboard) {
            return true;
        }

        if (item.toLowerCase() === Permissions.insights.toLowerCase()) {
            return localStorage.getItem('omnia-flags').indexOf(Permissions.insights) !== -1;
        }

        if (item === 'adps' && !this.hasLicense(item)) {
            return false;
        }

        if (item === Permissions.strategy && !this.hasLicense('adps')) {
            return false;
        }

        if (localStorage.getItem('omnia-orgadmin') === 'true') {
            return true;
        }

        if (!localStorage.getItem('omnia-lic')) {
            return false;
        }

        if (!localStorage.getItem('omnia-rights')) {
            return false;
        } else {
            if (localStorage.getItem('omnia-rights') == null) {
                return false;
            }

            if (item === 'pricing' && !this.hasLicense('pricewatch')) {
                return false;
            }

            if (
                item === 'pricewatch' &&
                !this.hasLicense('pricewatch') &&
                !this.hasLicense('adps')
            ) {
                return false;
            }

            if (item === 'adps' && !this.hasLicense('adps')) {
                return false;
            }

            if (
                JSON.parse(localStorage.getItem('omnia-rights'))[
                    item === 'pricewatch' || item === 'adps' ? 'pricing' : item
                ] === 'ReadWrite'
            ) {
                return true;
            }

            if (
                JSON.parse(localStorage.getItem('omnia-rights'))[
                    item === 'pricewatch' || item === 'adps' ? 'ProductOverview' : item
                ] === 'ReadWrite'
            ) {
                return true;
            }

            if (
                JSON.parse(localStorage.getItem('omnia-rights'))[
                    item === 'pricewatch' || item === 'adps' ? 'pricing' : item
                ] === 'Read'
            ) {
                return true;
            }
        }
        return false;
    }

    public isAuthenticated(): Observable<boolean> {
        /* 
            Delay is needed when communicating to auth0
            It is not entirely sure why it's needed in detail
            but it might have to do with the process auth0 takes for reading a newly set
            silent auth token 
        */
        return this.auth.isLoading$.pipe(
            delay(300),
            switchMap(() => this.auth.isAuthenticated$)
        );
    }

    public renewToken(): void {
        this.store.dispatch(RenewTokenInitAction());
    }

    public hasWritePermission(item: string): boolean {
        return (
            localStorage.getItem('omnia-orgadmin') === 'true' ||
            (JSON.parse(localStorage.getItem('omnia-rights'))
                ? JSON.parse(localStorage.getItem('omnia-rights'))[item] === 'ReadWrite'
                : false)
        );
    }

    public getLicenses(): string[] {
        return localStorage.getItem('omnia-lic')
            ? JSON.parse(localStorage.getItem('omnia-lic'))
            : {};
    }
}
