export class UserToRegister {
    referenceId: string;
    activationCode: string;
    name: string;
    surname: string;
    password: string;
    confirmPassword: string;
    username: string;
    organizationName: string;
    acceptedTerms: string;
}

export class RegisterResponseModel {
    errorKey: string;
    remainingAttempts: string;
    success: boolean;
}
