import { Component, OnInit } from '@angular/core';
import { RegisterResponseModel, UserToRegister } from '@models/user-to-register';
import { SecurityService } from '@services/security/security.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-register-new-user',
    templateUrl: './register-new-user.component.html',
})
export class RegisterNewUserComponent implements OnInit {
    public isActivated: boolean;
    public user: UserToRegister;
    public isRegisterLoading: boolean;
    public errorMessage: string;

    public referenceValidation: {
        isLoading?: boolean;
        message?: string;
        isValid?: boolean;
    };

    public readonly registerForm = new FormGroup({
        activationCode: new FormControl('', [Validators.required]),
        name: new FormControl('', Validators.required),
        surname: new FormControl('', Validators.required),
        password: new FormControl('', [Validators.required, Validators.minLength(12)]),
        confirmPassword: new FormControl('', [Validators.required, Validators.minLength(12)]),
        acceptedTerms: new FormControl(false, Validators.required),
    });

    constructor(
        private readonly securityService: SecurityService,
        private readonly authenticationService: AuthenticationService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router
    ) {}

    ngOnInit(): void {
        this.user = new UserToRegister();

        this.referenceValidation = {
            isLoading: true,
        };

        this.user.referenceId = this.activatedRoute.snapshot.params['code'];

        if (this.user.referenceId) {
            this.validateReferenceId();
        }
    }

    public register(): void {
        const { acceptedTerms, activationCode, name, surname, password, confirmPassword } =
            this.registerForm.value;

        if (!acceptedTerms) {
            this.errorMessage = 'Please accept the terms and conditions in order to proceed.';
            return;
        }

        if (!activationCode) {
            this.errorMessage = 'ActivationCode is required';
            return;
        }

        this.registerForm.patchValue({
            activationCode: this.registerForm.value.activationCode.trim(),
        });

        if (!name) {
            this.errorMessage = 'Name is required';
            return;
        }

        if (!surname) {
            this.errorMessage = 'Surname is required';
            return;
        }

        if (password.length < 12) {
            this.errorMessage = 'Password too short';
            return;
        }

        if (password !== confirmPassword) {
            this.errorMessage = 'Password and confirm password are not match';
            return;
        }

        this.isRegisterLoading = true;
        this.authenticationService.clearLocalStorage();
        this.securityService
            .registerNewUser({
                ...this.registerForm.value,
                username: this.user.username,
                referenceId: this.user.referenceId,
            })
            .pipe(take(1))
            .subscribe(
                (res: RegisterResponseModel) => {
                    this.isRegisterLoading = false;
                    if (res.success) {
                        this.isActivated = true;
                        this.redirect();
                    }
                },
                (res?: RegisterResponseModel) => {
                    this.isRegisterLoading = false;
                    if (res?.errorKey === 'ActivationCodeError') {
                        this.errorMessage = 'Wrong activation code';
                    } else if (res?.errorKey === 'Expired') {
                        this.errorMessage =
                            'Your invitation has expired, please contact our customer service to receive a new invite link';
                    } else if (res?.errorKey === 'LimitExceeded') {
                        this.errorMessage =
                            'Maximum attempts for registration is exceeded, please ask the organization admin to send a new invitation';
                    } else {
                        this.errorMessage = 'Unable to activate this account';
                    }
                }
            );
    }

    // Kill me with fire
    private validateReferenceId(): void {
        this.securityService
            .validateReferenceId(this.user.referenceId)
            .pipe(take(1))
            .subscribe(
                (res: UserToRegister) => {
                    // Im using timeout here just to make UI a bit smoother
                    setTimeout(() => {
                        this.referenceValidation = {
                            isLoading: false,
                            isValid: true,
                            message: '',
                        };
                    }, 1000);
                    this.user.username = res.username;
                    this.user.organizationName = res.organizationName;
                    this.authenticationService.clearLocalStorage();
                },
                (error: HttpErrorResponse) => {
                    let errorMessage: string;
                    if (error.status === 404) {
                        errorMessage = 'Invalid invitation link';
                    } else if (error.status === 400) {
                        if (error.error === 'Expired') {
                            errorMessage =
                                'Your invitation has expired, please contact our customer service to receive a new invite link';
                        } else if (error.error === 'LimitExceeded') {
                            errorMessage =
                                'Maximum attempts for registration is exceeded, please ask the organization admin to send a new invitation';
                        } else {
                            errorMessage =
                                'Invitation has expired, please contact your administrator';
                        }
                    } else {
                        errorMessage = 'Something went wrong, please try again';
                    }

                    this.referenceValidation = {
                        isLoading: false,
                        isValid: false,
                        message: errorMessage,
                    };
                }
            );
    }

    private redirect(): void {
        window.location.href = 'https://app.omniaretail.com/';
    }
}
