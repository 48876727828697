import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class IconService {
    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

    addIcons() {
        this.matIconRegistry.addSvgIcon(
            'csv-filled',
            this.domSanitizer.bypassSecurityTrustResourceUrl('icons/csv-fill.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'csv-stroke',
            this.domSanitizer.bypassSecurityTrustResourceUrl('icons/csv-stroke.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'xls-filled',
            this.domSanitizer.bypassSecurityTrustResourceUrl('icons/xls-fill.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'xls-stroke',
            this.domSanitizer.bypassSecurityTrustResourceUrl('icons/xls-stroke.svg')
        );
    }
}
