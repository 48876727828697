import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare global {
    interface Window {
        JobMode: { [key: string]: string | number };
        JobAction: { [key: string]: string | number };
        FrequencyScope: { [key: string]: string | number };
        ChannelScope: { [key: string]: string | number };
    }
}

if (environment.production) {
    enableProdMode();
}

(() => {
    window.JobMode = {
        0: 'Shop',
        1: 'feeds',
        Group: 1,
        Shop: 0,
    };

    window.JobAction = {
        0: 'Schedule',
        1: 'Cancel',
        2: 'Status',
        3: 'Details',
        Cancel: 1,
        Details: 3,
        Schedule: 0,
        Status: 2,
    };

    window.FrequencyScope = {
        0: 'Never',
        1: 'Hourly',
        2: 'Daily',
        3: 'Weekly',
        4: 'Monthly',
        Daily: 2,
        Hourly: 1,
        Monthly: 4,
        Never: 0,
        Weekly: 3,
    };

    window.ChannelScope = {
        0: 'Marketing',
        1: 'Report',
        2: 'Export',
        Export: 2,
        Marketing: 0,
        Report: 1,
    };

    platformBrowserDynamic().bootstrapModule(AppModule).catch(console.error);
})();
