<button
    *ngIf="isRaisedButton; else basicButton"
    mat-raised-button
    [disabled]="disabled"
    [color]="color"
    class="flex items-center justify-center"
    (click)="click()"
>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<ng-template #basicButton
    ><button
        mat-button
        [disabled]="disabled"
        [color]="color"
        class="flex items-center justify-center"
        (click)="click()"
    >
        <ng-container [ngTemplateOutlet]="buttonContent"></ng-container></button
></ng-template>

<ng-template #buttonContent>
    <div [ngClass]="{ 'flex-row-reverse': hasIconAfterText }" class="flex items-center">
        <mat-icon class="mt-1 {{ iconSize }}" *ngIf="icon">{{ icon }} </mat-icon>
        <span
            *ngIf="text"
            class="leading-mat-button {{ textSize }}"
            [ngClass]="{
                'mx-2': icon || hasIconAfterText,
                'text-white': isRaisedButton && color === 'primary'
            }"
            >{{ text }}</span
        >
    </div>
</ng-template>
