import { createReducer, on } from '@ngrx/store';
import {
    SetIsSsoInitActionSuccess,
    UserLoginFailedAction,
    UserLoginSuccessAction,
} from '@store/actions/authentication.actions';
import {
    OrganizationSetInitAction,
    AccessSetSuccessAction,
} from '@store/actions/organization.actions';
import { AuthState } from '@store/models/authentication.model';

export const authenticationFeatureKey = 'authentication';

export const initialAuthState: AuthState = {
    username: undefined,
    token: undefined,
    features: undefined,
    isOrgAdmin: undefined,
    authorization: undefined,
    errorMessage: undefined,
};

export const userReducer = createReducer(
    initialAuthState,
    on(UserLoginSuccessAction, (state = initialAuthState, { payload }) => ({
        ...state,
        ...payload,
    })),
    on(SetIsSsoInitActionSuccess, (state = initialAuthState, { payload }) => ({
        ...state,
        ...payload,
    })),
    on(UserLoginFailedAction, (state = initialAuthState, { payload }) => ({
        ...state,
        ...payload,
    })),
    on(OrganizationSetInitAction, (state = initialAuthState, { authorization }) => ({
        ...state,
        authorization,
    })),
    on(AccessSetSuccessAction, (state = initialAuthState, { features }) => ({
        ...state,
        features,
    }))
);
