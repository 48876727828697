import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class BrowserStorageService {
    private readonly storage = window.localStorage;

    public setItem(item: string, description: string): void {
        this.storage.setItem(item, description);
    }

    public getItem(itemName: string): string {
        return this.storage.getItem(itemName);
    }

    public hasItem(itemName: string): boolean {
        return itemName in this.storage;
    }

    public removeItem(itemName: string): void {
        this.storage.removeItem(itemName);
    }
}
