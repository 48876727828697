import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../../../environments/environment';

const { domain, clientId, audience, redirectUri } = environment.features.singleSignOn.properties;
const env = window['__env'];
@NgModule({
    imports: [
        CommonModule,
        AuthModule.forRoot({
            domain: env?.ssoDomain || domain,
            clientId: env?.ssoClientID || clientId,
            audience,
            redirectUri: env?.ssoRedirectUri || redirectUri,
            useRefreshTokens: true,
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
    ],
})
export class Auth0Module {}
