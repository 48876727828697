import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { OrganizationsPortalInterface } from '@models/interfaces/portal.model.interface';

@Injectable({
    providedIn: 'root',
})
export class PortalService {
    constructor(private readonly http: HttpClient) {}

    public getOrganizationAndPortals(): Observable<OrganizationsPortalInterface> {
        return this.http.get<OrganizationsPortalInterface>(
            environment.bffAbsEndpoint + '/user/organizationsPortals'
        );
    }

    public getFeatureFlags(): Observable<string[]> {
        return this.http.get<string[]>(environment.bffAbsEndpoint + '/Portal/featureFlags');
    }

    public switchPortal(portalName: string): Observable<string> {
        return this.http.get<string>(environment.bffAbsEndpoint + '/portal/switch/' + portalName);
    }
}
