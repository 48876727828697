import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-base-button',
    templateUrl: './base-button.component.html',
    styleUrls: ['./base-button.component.scss'],
})
export class BaseButtonComponent extends MatButton {
    @Input() disabled = false;
    @Input() text: string;
    @Input() icon: string;
    @Input() hasIconAfterText = false;
    @Input() textSize: 'text-sm' | 'text-base' | 'text-lg' | 'text-xl' = 'text-base';
    @Input() iconSize: 'text-sm' | 'text-base' | 'text-lg' | 'text-xl' = 'text-base';
    @Input() isRaisedButton = false;

    @Output() clickEvent = new EventEmitter<void>();

    public click(): void {
        if (!this.disabled) {
            this.clickEvent.emit();
        }
    }
}
