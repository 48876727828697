import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[isStretchableColumn]',
})
export class IsStretchableColumnDirective implements OnInit {
    @Input() columnKey: string;
    isResizing = false;
    lastDownX = 0;
    onlyYDown: number;
    defaultWidth: number;
    currentWidth: number;
    allowedMinimumX: number;
    finalWidth: number;

    constructor(private element: ElementRef) {}

    getColumnArrayFromLS = () => {
        const columnWidthString = localStorage.getItem(
            'advisor-table-columns-width'
        );
        return columnWidthString ? columnWidthString.split(',') : [];
    };

    getCurrentColumnFromLS = () => {
        const currentColumn = this.getColumnArrayFromLS().find(el => {
            const colName = el.split('::')[0];
            const colWidth = el.split('::')[1];
            if (
                colName &&
                colName === this.columnKey &&
                colWidth &&
                Number(colWidth) > 0
            ) {
                return true;
            } else {
                return false;
            }
        });
        return currentColumn;
    };

    getAndSetDefaultWidth = (container: HTMLElement) => {
        const containerWidth = container.offsetWidth + 15; // 15px is for sort icon.
        const currentColumn = this.getCurrentColumnFromLS();

        if (!currentColumn) {
            this.defaultWidth = containerWidth;
            return;
        }

        const columnWidth = Number(currentColumn.split('::')[1]);
        if (currentColumn && columnWidth && columnWidth > containerWidth) {
            this.defaultWidth = columnWidth;
            container.style.width = this.defaultWidth + 'px';
        } else {
            this.defaultWidth = containerWidth;
        }
    };

    setDefaultWidthToLS = () => {
        const currentColumn = this.getCurrentColumnFromLS();
        const updatedColWidthString = `${this.columnKey}::${this.finalWidth}`;
        let finalColumnWidthString: string;
        if (currentColumn) {
            const otherColumns = this.getColumnArrayFromLS().filter(
                el => el !== currentColumn
            );
            finalColumnWidthString = [
                ...otherColumns,
                updatedColWidthString,
            ].join(',');
        } else {
            finalColumnWidthString = [
                ...this.getColumnArrayFromLS(),
                updatedColWidthString,
            ].join(',');
        }
        localStorage.setItem(
            'advisor-table-columns-width',
            finalColumnWidthString
        );
    };

    ngOnInit() {
        const el: HTMLElement = this.element.nativeElement;

        const parentContainer = el.parentElement.parentElement;
        this.getAndSetDefaultWidth(parentContainer);
        el.addEventListener('dragstart', e => {
            this.isResizing = true;
            this.lastDownX = e.clientX;
            this.currentWidth = parentContainer.offsetWidth;

            this.allowedMinimumX =
                this.currentWidth > this.defaultWidth
                    ? this.lastDownX - this.currentWidth + this.defaultWidth
                    : this.lastDownX - this.defaultWidth;
        });
        el.addEventListener('drag', e => {
            if (
                !e.clientX ||
                !this.isResizing ||
                e.clientX < this.allowedMinimumX
            ) {
                return;
            }
            const widthDiff = this.lastDownX - e.clientX;
            this.finalWidth = this.currentWidth - widthDiff;
            parentContainer.style.width = this.finalWidth + 'px';
        });
        el.addEventListener('dragend', () => {
            this.isResizing = false;
            this.setDefaultWidthToLS();
            el.removeEventListener('drag', () => false);
        });
    }
}
