import { Injectable } from '@angular/core';
import { IToastService } from '@models/interfaces/toast-service.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterComponent } from '@quantum/snackbar/toaster/toaster.component';
import { MutationNotificationComponent } from '@quantum/snackbar/mutation-notification/mutation-notification.component';

@Injectable({
    providedIn: 'root',
})
export class ToastService implements IToastService {
    private defaultDuration = 3500;

    constructor(private readonly snackBar: MatSnackBar) {}

    public notify(message: string, duration?: number, className?: string): void {
        this.snackBar.open(message, 'Close', {
            duration: duration || this.defaultDuration,
            panelClass: className,
        });
    }

    public notifySuccess(message: string, duration?: number): void {
        return this.notify(message, duration, 'success-dialog');
    }

    public notifyError(message: string, duration?: number): void {
        return this.notify(message, duration, 'error-dialog');
    }

    public notifyStatus(message: string, duration?: number): void {
        return this.notify(message, duration, 'status-dialog');
    }

    public notifyStatusHTML(message: string, duration: number): void {
        this.snackBar.openFromComponent(ToasterComponent, {
            duration,
            panelClass: 'status-dialog',
            data: message,
        });
    }

    public notifyChanges(): void {
        this.snackBar.openFromComponent(MutationNotificationComponent, {
            panelClass: 'status-dialog',
            verticalPosition: 'top',
            horizontalPosition: 'center',
        });
    }
}
