export class EnvironmentService {
    // The values that are defined here are the default values that can
    // be overridden by env.js

    // API url
    public frontEndApi = '';
    public googleId = '';
    public features = '';
    public ssoClientID = '';
    public ssoDomain = '';
    public lookerDomain = '';
    public lookerDashboardId = '';

    // Whether or not to enable debug mode
}
