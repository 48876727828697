import { Component, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
    selector: 'app-toaster',
    templateUrl: './toaster.component.html',
})
export class ToasterComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public readonly data: unknown,
        public readonly snackBar: MatSnackBar,
        private readonly router: Router
    ) {}

    public processLinks(e: MouseEvent): void {
        const element = e.target as HTMLElement;
        const link = element.getAttribute('href');

        if (element.nodeName === 'A' && !link.includes('//')) {
            e.preventDefault();
            void this.router.navigate([link]);
        }
    }
}
