import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '@models/user.model';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { UserRightModel } from '@models/user-right.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginModel } from '@models/api/multi-portal-login.model';
import { RegisterResponseModel, UserToRegister } from '@models/user-to-register';
import { UsernameModel } from '@models/api/username.model';
import { ForgotPasswordUpdateModel } from '@models/api/forgot-password-update.model';
import { Store } from '@ngrx/store';
import { AppState } from '@store/models/app.model';
import { UserLoggedOutInitAction } from '@store/actions/authentication.actions';

@Injectable({
    providedIn: 'root',
})
export class SecurityService {
    constructor(private readonly store: Store<AppState>, private readonly http: HttpClient) {}

    validateReferenceId(code: string): Observable<UserToRegister> {
        return this.http.get<UserToRegister>(
            environment.bffAbsEndpoint + '/UserInvitation/' + code
        );
    }

    registerNewUser(user: UserToRegister): Observable<RegisterResponseModel> {
        return this.http.post<RegisterResponseModel>(
            environment.bffAbsEndpoint + '/UserInvitation/activate/',
            user
        );
    }

    requestForgotPassword(username: UsernameModel): Observable<boolean> {
        return this.http.post<boolean>(environment.bffAbsEndpoint + '/forgotpassword', username);
    }

    public validateForgotPasswordReferenceId(code: string): Observable<UsernameModel> {
        return this.http.get<UsernameModel>(environment.bffAbsEndpoint + '/forgotpassword/' + code);
    }

    public updateForgotPassword(request: ForgotPasswordUpdateModel): Observable<boolean> {
        return this.http.post<boolean>(
            environment.bffAbsEndpoint + '/forgotpassword/update',
            request
        );
    }

    public login(user: UserModel): Observable<LoginModel> {
        return this.http.post<LoginModel>(environment.bffAbsEndpoint + '/authentication', user);
    }

    public getUserPermissions(): Observable<UserRightModel> {
        return this.http.get<UserRightModel>(environment.bffAbsEndpoint + '/User/Permissions');
    }

    hasFeatureFlag(feature: string): boolean {
        if (!localStorage.getItem('omnia-flags')) {
            return false;
        }
        return !!JSON.parse(localStorage.getItem('omnia-flags')).find((x) => x === feature);
    }

    /**
     * @deprecated The method should not be used, use storage service instead
     */
    saveToLocalStorage(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    public getUserCurrency(): Observable<string> {
        return this.http.get(environment.bffAbsEndpoint + '/User/CurrencySymbol', {
            responseType: 'text',
        });
    }

    public logout(): void {
        this.store.dispatch(UserLoggedOutInitAction());
    }

    // TODO investigate how much effort it would be to remove
    // Still needed for legacy login
    public isTokenExpired(): boolean {
        const token = localStorage.getItem('Token');

        if (!token || token === 'undefined') {
            return true;
        }

        const helper = new JwtHelperService();
        const isExpired = helper.isTokenExpired(token);

        return isExpired;
    }
}
