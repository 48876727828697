import { Injectable } from '@angular/core';
import {
    selectFirstName,
    selectLastName,
    selectOrganizations,
    selectPortals,
    selectUserEmail,
} from '@store/selectors/authentication.selectors';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '@store/models/app.model';
import { map, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { selectLastVisitedPortal } from '@store/selectors/preferences-selectors';

// This can really be any as there are no types available on npm
declare const aptrinsic: (string, arg: unknown, unknown) => void;

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    constructor(private readonly store: Store<AppState>) {}

    // Context can also be any
    public addUserAction(name: string, context?: any): void {
        try {
            datadogRum.addUserAction(name, { OmniaCustomResponse: context });
        } catch {}
    }

    public initLogging(): void {
        const { clientToken, applicationId, sampleRate, service, datacenter } = environment.logging;

        datadogRum.init({
            clientToken,
            applicationId,
            sampleRate,
            service,
            datacenter,
        });
    }

    public addRumGlobalContext(key: string, value: string): void {
        datadogRum.addRumGlobalContext(key, value);
    }

    public addAnalytics(): Observable<void> {
        return this.store.select(selectUserEmail).pipe(
            withLatestFrom(
                this.store.select(selectFirstName),
                this.store.select(selectLastName),
                this.store.select(selectPortals),
                this.store.select(selectOrganizations),
                this.store.select(selectLastVisitedPortal)
            ),
            map(([email, firstName, lastName, portals, organizationName, lastVisitedPortal]) => {
                // TODO: move licenses to store
                // Legacy still relies on localstorage
                const userLicenses = localStorage.getItem('omnia-lic') || '';

                aptrinsic(
                    'identify',
                    {
                        // User Fields
                        id: email?.toLowerCase(),
                        email,
                        firstName,
                        lastName,
                        signUpDate: Date(),
                    },
                    {
                        // Account Fields
                        id: email?.toLowerCase()?.match(/[^@]*$/g)[0],
                        licenses: userLicenses,
                        portals: portals?.toString(),
                        organizationName: organizationName?.toString(),
                        activePortal: lastVisitedPortal,
                    }
                );

                return;
            })
        );
    }
}
