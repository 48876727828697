import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DirectiveModule } from './utils/directives/directive.module';
import { RoutingState } from './services/routes/RoutingState.service';
import { LoginModule } from './features/authentication/authentication.module';
import { Interceptor } from './services/interceptor.service';
import { MaterialModule } from './vendor-features/material.module';
import { LoaderService } from './services/loader/loader.service';
import { StoreModule } from '@ngrx/store';
import { IconService } from './services/icon.service';
import { EnvServiceProvider } from './services/environment/environment.service.provider';
import { BrowserStorageService } from './services/common/localstorage.service';
import { Auth0Module } from './vendor-features/auth0/auth0.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { storageReducer } from '@store/reducers/app.reducers';

const debugStore = !environment.production
    ? StoreDevtoolsModule.instrument({
          maxAge: 25,
      })
    : [];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        DirectiveModule,
        CommonModule,
        HttpClientModule,
        LoginModule,
        Auth0Module,
        MaterialModule,
        StoreModule.forRoot(
            {},
            {
                metaReducers: storageReducer,
                runtimeChecks: {
                    // The metareducer needs this
                    strictStateImmutability: false,
                },
            }
        ),
        debugStore,
        EffectsModule.forRoot([]),
    ],
    providers: [
        LoaderService,
        IconService,
        RoutingState,
        BrowserStorageService,
        EnvServiceProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule {}
