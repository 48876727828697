import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FileSystemService {
    constructor(private readonly http: HttpClient) {}

    public getVersion(): Observable<string> {
        return this.http
            .get('version.txt', {
                headers: {
                    'Cache-Control': 'no-cache',
                },
                responseType: 'text',
            })
            .pipe(filter((res: string) => !res.includes('#version#')));
    }
}
