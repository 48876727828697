<form name="form" (submit)="register()" *ngIf="isActivated" class="text-center">
    <div>
        <strong>Congratulations, your account has been successfully created</strong>
    </div>
    <div class="mt-8">
        <p-progressSpinner [style]="{ width: '4rem', height: '4rem' }"></p-progressSpinner>
    </div>
</form>

<form *ngIf="!isActivated" name="form" [formGroup]="registerForm" (submit)="register()">
    <div class="loading-container" *ngIf="referenceValidation.isLoading">
        <p-progressSpinner [style]="{ width: '4rem', height: '4rem' }"></p-progressSpinner>
    </div>
    <div
        class="error-container"
        *ngIf="!referenceValidation.isLoading && !referenceValidation.isValid"
    >
        <strong>{{ referenceValidation.message }}</strong>
    </div>
    <div *ngIf="!referenceValidation.isLoading && referenceValidation.isValid">
        <div *ngIf="!!errorMessage" style="margin: 1rem 0" class="alert alert-danger">
            {{ errorMessage }}
        </div>

        <mat-form-field>
            <mat-label>Organization</mat-label>
            <input matInput disabled [value]="user.organizationName" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput disabled [value]="user.username" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Activation Code</mat-label>
            <span matSuffix> <mat-icon>public</mat-icon> </span>

            <input
                matInput
                id="activationCode"
                formControlName="activationCode"
                type="text"
                placeholder="Activation code"
                autocomplete="off"
            />
        </mat-form-field>

        <mat-form-field>
            <mat-label>First name</mat-label>
            <span matSuffix> <mat-icon>account_circle</mat-icon> </span>
            <input
                matInput
                formControlName="name"
                id="name"
                name="name"
                type="text"
                autocomplete="off"
            />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Last name</mat-label>
            <span matSuffix> <mat-icon>account_circle</mat-icon> </span>
            <input
                matInput
                formControlName="surname"
                id="surname"
                name="surname"
                type="text"
                autocomplete="off"
            />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Password</mat-label>
            <span matSuffix> <mat-icon>lock</mat-icon> </span>
            <input
                matInput
                formControlName="password"
                id="password"
                type="password"
                placeholder="Password, minimum 12 characters"
                autocomplete="new-password"
            />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Confirm password</mat-label>
            <span matSuffix> <mat-icon>lock</mat-icon> </span>
            <input
                matInput
                formControlName="confirmPassword"
                id="confirmPassword"
                type="password"
                placeholder="Confirm password"
                autocomplete="off"
            />
        </mat-form-field>

        <div class="input-field login_form terms-container">
            <input
                id="confirmTerms"
                class="filled-in"
                name="confirmTerms"
                type="checkbox"
                formControlName="acceptedTerms"
            />
            <label for="confirmTerms"
                >I confirm I have read, consent and agree to Omnia's
                <a target="_blank" href="//www.omniaretail.com/client-privacy-statement"
                    >privacy policy</a
                >.</label
            >
        </div>
        <button
            mat-raised-button
            type="submit"
            [disabled]="!registerForm.valid || !registerForm.value.acceptedTerms"
            class="login_button"
        >
            Register
        </button>
    </div>
</form>
