export class UserModel {
    public username: string;
    public password: string;
    public domain?: string;
    public type?: string;
}

export class MultiPortalUserModel {
    public username: string;
    public portalNames: string;
    public isSso: boolean;
    public password?: string;
    public portals: RolesPortalMode[];
    public stringPortals?: string[];
    public status: string;
    public dateOfRegister: Date;
    public firstName: string;
    public lastName: string;
    public createdDate: string;
}

export class UserInvitationResponse {
    message: string;
    success: boolean;
}

export class RolesPortalMode {
    public portal?: PortalModel;
    public roles?: RoleModel[];
}

export class PortalModel {
    public name: string;
    public id: string;
}

export class RoleModel {
    public name: string;
    public id: string;
}

export class PermissionModel {
    public connect: boolean;
    public marketing: boolean;
    public product_overview: boolean;
    public pricing_strategy: boolean;
    public pricewatch_export: boolean;
    public reports: boolean;
    public performance: boolean;
    public settings: boolean;
}

export enum SuperUser {
    SuperAdmin = 'SuperAdmin',
    OrganizationAdmin = 'OrganizationAdmin',
}
