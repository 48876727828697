import { Component, OnInit } from '@angular/core';
import { interval, timer } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { FileSystemService } from '@services/filesystem/filesystem.service';
import { HealthCheckService } from '@services/healthcheck/healthcheck.service';
import { ToastService } from '@services/toast/toast.service';
import { RoutingState } from '@services/routes/RoutingState.service';
import { IconService } from '@services/icon.service';
import { LoggingService } from '@services/logging/logging.service';
import { EnvironmentService } from '@services/environment/environment.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Store } from '@ngrx/store';
import { UserLoginSuccessAction } from '@store/actions/authentication.actions';
import { AppState } from '@store/models/app.model';
import { LoaderService } from '@services/loader/loader.service';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterEvent,
} from '@angular/router';
import { SetSystemHasErrorsInitAction } from '@store/actions/status.actions';
import { StatusInterface } from '@features/status/model';
import { filterNullAndUndefined } from '@utils/helpers/is-defined';

interface Iindicator {
    description: string;
    indicator: string;
}

interface IMaintenanceStatus {
    id: string;
    name: string;
    status: string;
    shortlink: string;
    scheduled_for: string;
    scheduled_until: string;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    public readonly authUser$ = this.authenticationService
        .getLoggedInUser()
        .pipe(
            take(1),
            filter(filterNullAndUndefined),
            tap(({ email, name = '', ...user }) => {
                const fullName = name.split(' ');
                const isOmniaUser = email.includes('omniaretail.com');

                this.store.dispatch(
                    UserLoginSuccessAction({
                        payload: {
                            ...user,
                            isOmniaUser,
                            username: email,
                            firstName: fullName[0] || '',
                            lastName: fullName[fullName.length - 1] || '',
                        },
                    })
                );
            }),
            switchMap(() => this.loggingService.addAnalytics())
        )
        .subscribe();

    private readonly isAuthenticated = !!localStorage.getItem('Token');

    constructor(
        private readonly fileSystemService: FileSystemService,
        private readonly HealthCheckService: HealthCheckService,
        private readonly toast: ToastService,
        private readonly authenticationService: AuthenticationService,
        private readonly routingState: RoutingState,
        private readonly router: Router,
        private readonly loaderService: LoaderService,
        private readonly store: Store<AppState>,
        private readonly iconService: IconService,
        private readonly loggingService: LoggingService,
        private readonly environmentService: EnvironmentService
    ) {}

    ngOnInit(): void {
        this.activityTracker();
        this.environmentService.frontEndApi;
        this.iconService.addIcons();
        this.manageStatusToast();
        this.manageMaintenanceToast();
        this.routingState.loadRouting();

        this.router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event);
        });

        this.getVersion();
        this.loggingService.initLogging();
    }

    // Kill me with fire
    saveStatusSettings = ({ page, status }, displayState = true) => {
        const currentStatusIndicatorText = `${status?.indicator}--${page.updated_at}`;

        const statusIndicatorTime = Number(localStorage.getItem('statusIndicatorTime'));

        if (!statusIndicatorTime) {
            localStorage.setItem('statusIndicatorTime', String(Date.now()));
        }
        const oldStatusIndicatorText = localStorage.getItem('statusIndicator');

        localStorage.setItem('statusIndicator', currentStatusIndicatorText);

        const currentStatusIndicatorTime = Number(localStorage.getItem('statusIndicatorTime'));

        const timeDiffInMinutes = (Date.now() - currentStatusIndicatorTime) / (1000 * 60);

        if (status.indicator.includes('maintenance')) {
            if (timeDiffInMinutes > 15 || currentStatusIndicatorText !== oldStatusIndicatorText) {
                localStorage.setItem('statusIndicatorState', displayState.toString());
                localStorage.setItem('statusDisplayIndicatorForMaintenance', '1');
            } else {
                localStorage.setItem('statusDisplayIndicatorForMaintenance', '0');
            }
        } else {
            if (timeDiffInMinutes > 15 || currentStatusIndicatorText !== oldStatusIndicatorText) {
                localStorage.setItem('statusIndicatorState', displayState.toString());
                localStorage.setItem('statusDisplayIndicator', '1');
            } else {
                localStorage.setItem('statusDisplayIndicator', '0');
            }
        }

        return status;
    };

    // Kill me with fire
    manageStatusToast = () => {
        timer(5000, 900000)
            .pipe(
                mergeMap(() => this.HealthCheckService.getStatus()),
                map((status) => {
                    if (status) {
                        this.dispatchStatusState(status);
                        return this.saveStatusSettings(status);
                    }
                }),
                filter(({ indicator = '' }: Iindicator) => {
                    if (indicator) {
                        return !indicator.includes('none') && !indicator.includes('maintenance');
                    }
                })
            )
            .subscribe((status: any) => {
                if (localStorage.getItem('statusIndicatorState') === 'true') {
                    const statusText = this.HealthCheckService.getStatusText(status);
                    const statusLink = this.isAuthenticated
                        ? `<a href='/status' class="toast-btn-link">Follow status</a>`
                        : `<a href='//omniaretail.statuspage.io' class="toast-btn-link">Follow status</a>`;
                    if (localStorage.getItem('statusDisplayIndicator') === '1') {
                        this.toast.notifyStatusHTML(
                            `${statusText} More information on ${statusLink}.`,
                            Infinity
                        );
                    }
                }
            });
    };

    // Kill me with fire
    manageMaintenanceToast() {
        interval(30000)
            .pipe(
                mergeMap(() => this.HealthCheckService.getStatus()),
                map((status: any) => {
                    if (status) {
                        return status.scheduled_maintenances;
                    }
                }),
                filter((smArr) => smArr && Boolean(smArr.length))
            )
            .subscribe((maintenanceArray: IMaintenanceStatus[]) => {
                const schedduledMaintenance = maintenanceArray.find(
                    (mainttenance) =>
                        mainttenance.status === 'scheduled' || mainttenance.status === 'in_progress'
                );

                if (schedduledMaintenance) {
                    const defaultMessage = 'Maintenance Scheduled';

                    const manageTime = (dateString: string) => {
                        const date = new Date(dateString);
                        return `${date.toLocaleString()}`;
                    };

                    const statusText = `${
                        schedduledMaintenance.name || defaultMessage
                    } <br/> ${manageTime(schedduledMaintenance.scheduled_for)} to ${manageTime(
                        schedduledMaintenance.scheduled_until
                    )}`;
                    const statusLink = this.isAuthenticated
                        ? `<a href="${schedduledMaintenance.shortlink}" target="_blank" class="toast-btn-link">Follow status</a>`
                        : `<a href="${schedduledMaintenance.shortlink}" target="_blank" class="toast-btn-link not-logged-in">Follow status</a>`;

                    if (localStorage.getItem('statusDisplayIndicatorForMaintenance') === '1') {
                        this.toast.notifyStatusHTML(`${statusText}<br/>${statusLink}`, Infinity);
                    }
                }
            });
    }

    public getVersion(): void {
        this.fileSystemService.getVersion().subscribe((newAppVersion: string) => {
            const currentVersion = Number(newAppVersion.match(/\d+/g).map(Number).join(''));
            localStorage.setItem('version', currentVersion.toString());
        });
    }

    private activityTracker(): void {
        document.addEventListener('keypress', () => this.authenticationService.renewToken());
    }

    // TODO: investigate if this is still needed
    private navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.loggingService.addUserAction('url', { name: event.url });
        }

        if (
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        ) {
            this.loaderService.showLoader(false);
        }
    }

    private dispatchStatusState(status: StatusInterface): void {
        const hasIssues =
            Boolean(status.incidents.length) || Boolean(status.scheduled_maintenances.length);

        this.store.dispatch(
            SetSystemHasErrorsInitAction({
                payload: {
                    hasSystemErrors: hasIssues,
                },
            })
        );
    }
}
