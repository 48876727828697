import { NgModule } from '@angular/core';
import { IsDevDirective } from './is-dev/is-dev.directive';
import { IsStretchableColumnDirective } from './is-stretchable/is-stretchable.directive';

const directives = [IsDevDirective, IsStretchableColumnDirective];

@NgModule({
    declarations: directives,
    exports: directives,
})
export class DirectiveModule {}
