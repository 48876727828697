import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterNewUserComponent } from './register-new-user/register-new-user.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordUpdateComponent } from './forgot-password-update/forgot-password-update.component';
import { PrimeNgModule } from '@vendor-features/prime-ng.module';
import { StoreModule } from '@ngrx/store';
import { authenticationFeatureKey, userReducer } from '@store/reducers/authentication.reducers';
import { AuthenticationComponent } from './authentication/authentication.component';
import { MaterialModule } from '@vendor-features/material.module';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationEffectsService } from '@store/effects/authentication.effects';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        PrimeNgModule,
        StoreModule.forFeature(authenticationFeatureKey, userReducer),
        EffectsModule.forFeature([AuthenticationEffectsService]),
    ],
    declarations: [
        AuthenticationComponent,
        RegisterNewUserComponent,
        ForgotPasswordComponent,
        ForgotPasswordUpdateComponent,
    ],
})
export class LoginModule {}
