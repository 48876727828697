import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthForms } from '../../features/authentication/authentication/auth.model';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    public errorMessage: string;
    public readonly loginComponents = ['Login', 'Forgot password', 'Update password', 'Register'];
    public readonly authForms = AuthForms;

    private readonly currentSelectedIndex$ = new BehaviorSubject(0);

    public updateTabIndex(index: number): void {
        this.currentSelectedIndex$.next(index);
    }

    public getCurrentIndex(): Observable<number> {
        return this.currentSelectedIndex$.asObservable();
    }
}
