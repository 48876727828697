import { Permissions } from '@models/enum/permissions';
import { createSelector } from '@ngrx/store';
import { AppState } from '@store/models/app.model';
import { AuthState } from '@store/models/authentication.model';

const selectAuthenticationFeature = ({ authentication }: AppState) => authentication;

export const selectUserEmail = createSelector(
    selectAuthenticationFeature,
    ({ username }: AuthState) => username
);

export const selectIsInternalEmployee = createSelector(
    selectAuthenticationFeature,
    ({ isOmniaUser }: AuthState) => isOmniaUser
);

export const selectIsSingleSignOnEnabled = createSelector(
    selectAuthenticationFeature,
    ({ isSingleSignOnEnabled }: AuthState) => isSingleSignOnEnabled
);

export const selectFirstName = createSelector(
    selectAuthenticationFeature,
    ({ firstName }: AuthState) => firstName
);

export const selectLastName = createSelector(
    selectAuthenticationFeature,
    ({ lastName }: AuthState) => lastName
);

export const selectLoginFailedMessage = createSelector(
    selectAuthenticationFeature,
    ({ errorMessage }: AuthState) => errorMessage
);

export const selectAuthorization = createSelector(
    selectAuthenticationFeature,
    ({ authorization }: AuthState) => authorization
);

export const selectOrganizations = createSelector(
    selectAuthenticationFeature,
    ({ authorization }: AuthState) => authorization?.map((org) => org[0])
);

export const selectPortals = createSelector(
    selectAuthenticationFeature,
    ({ authorization }: AuthState) => authorization?.map((org) => org[1]).flat()
);

export const selectToken = createSelector(
    selectAuthenticationFeature,
    ({ token }: AuthState) => token
);

export const selectLoggedInUser = createSelector(
    selectUserEmail,
    selectToken,
    (username, token) => ({
        username,
        token,
    })
);

export const selectFeatures = createSelector(
    selectAuthenticationFeature,
    ({ features }: AuthState) => features
);

export const selectIsFeatureInsightEnabled = createSelector(
    selectAuthenticationFeature,
    ({ features }: AuthState) => features?.find(({ key }) => key === Permissions.insights)?.enabled
);
