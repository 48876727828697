<form name="form" [formGroup]="forgotPasswordUpdate" (submit)="updatePassword()">
    <div *ngIf="!!errorMessage" class="alert alert-danger login-form-error">
        {{ errorMessage }}
    </div>

    <div *ngIf="referenceValidation?.isValid">
        <div *ngIf="!!successMessage" class="alert alert-success login-form-error">
            {{ successMessage }}
        </div>

        <mat-form-field>
            <mat-label>Email</mat-label>
            <span matSuffix> <mat-icon>account_circle</mat-icon> </span>
            <input
                [value]="request?.username"
                id="username"
                name="username"
                type="text"
                autocomplete="off"
                matInput
                readonly
            />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Password</mat-label>
            <span matSuffix> <mat-icon>lock</mat-icon> </span>
            <input
                formControlName="newPassword"
                id="password"
                name="password"
                type="password"
                placeholder="Password, minimum 12 characters"
                autocomplete="off"
                matInput
            />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Confirm password</mat-label>
            <span matSuffix> <mat-icon>lock</mat-icon> </span>
            <input
                formControlName="confirmPassword"
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder="Password, minimum 12 characters"
                autocomplete="off"
                matInput
            />
        </mat-form-field>

        <button
            mat-raised-button
            type="submit"
            [disabled]="!forgotPasswordUpdate.valid"
            class="login_button"
        >
            Update
        </button>
    </div>
</form>
