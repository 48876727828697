import { NgModule } from '@angular/core';
import {
    CheckboxModule,
    ChipsModule,
    DropdownModule,
    MessageModule,
    MultiSelectModule,
    OverlayPanelModule,
    ProgressSpinnerModule,
    TreeModule,
} from 'primeng';

@NgModule({
    exports: [
        MultiSelectModule,
        CheckboxModule,
        DropdownModule,
        MessageModule,
        ProgressSpinnerModule,
        ChipsModule,
        TreeModule,
        OverlayPanelModule,
    ],
})
export class PrimeNgModule {}
