import { createAction, props } from '@ngrx/store';
import {
    FeaturesInterface,
    OrganizationsPortalInterface,
} from '@models/interfaces/portal.model.interface';

export enum AuthenticationActions {
    // Organization actions
    SET_ORGANIZATION_INIT = 'SET_ORGANIZATION_INIT',
    SET_ORGANIZATION_SUCCESS = 'SET_ORGANIZATION_SUCCESS',
    SET_ORGANIZATION_FAILED = 'SET_ORGANIZATION_FAILED',

    // Access actions
    SET_ACCESS_SUCCESS = 'SET_ACCESS_SUCCESS',
    SET_ACCESS_FAILED = 'SET_ACCESS_FAILED',
}

export const OrganizationSetInitAction = createAction(
    AuthenticationActions.SET_ORGANIZATION_INIT,
    props<{ authorization: OrganizationsPortalInterface[] }>()
);

export const OrganizationSetSuccessAction = createAction(
    AuthenticationActions.SET_ORGANIZATION_SUCCESS
);

export const OrganizationSetFailedAction = createAction(
    AuthenticationActions.SET_ORGANIZATION_FAILED
);

export const AccessSetSuccessAction = createAction(
    AuthenticationActions.SET_ACCESS_SUCCESS,
    props<{ features: FeaturesInterface[] }>()
);

export const AccessSetFailedAction = createAction(AuthenticationActions.SET_ACCESS_FAILED);
