<form name="form" [formGroup]="forgotPasswordForm" (submit)="request()">
    <p>
        Forgot password
    </p>

    <div *ngIf="!!successMessage" class="alert alert-success login-form-error">
        {{ successMessage }}
    </div>

    <div *ngIf="!!errorMessage" class="alert alert-danger login-form-error">
        {{ errorMessage }}
    </div>

    <mat-form-field>
        <mat-label>Email</mat-label>
        <span matSuffix> <mat-icon>public</mat-icon> </span>
        <input
            formControlName="username"
            focus
            id="email"
            name="email"
            type="email"
            autocomplete="off"
            matInput
        />
    </mat-form-field>
    <button
        mat-raised-button
        type="submit"
        [disabled]="!forgotPasswordForm.valid"
        class="login_button"
    >
        Send
    </button>
</form>
