import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { v1 as uuid } from 'uuid';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService } from '@services/logging/logging.service';
import { cloneDeep } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from '@store/models/app.model';
import {
    RenewTokenInitAction,
    UserLoggedOutInitAction,
} from '@store/actions/authentication.actions';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(
        private readonly loggingService: LoggingService,
        private readonly store: Store<AppState>
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const GUID = uuid();

        if (request.url.includes('frontend')) {
            this.store.dispatch(RenewTokenInitAction());
        }

        if (request.url.indexOf('feed.omniaretail.com') === -1) {
            request = request.clone({ setHeaders: { 'X-Correlation': GUID } });
            if (localStorage.getItem('omnia-portal')) {
                request = request.clone({
                    setHeaders: {
                        Portal: localStorage.getItem('omnia-portal'),
                    },
                });
            }
        }

        const bodyForLog = cloneDeep(request.body);
        const summaryUrl = 'https://dm8lxbqw4p59.statuspage.io/api/v2/summary.json';
        const nonLoggedList = [summaryUrl, 'version.txt'];

        if (bodyForLog && bodyForLog.password) {
            bodyForLog.password = '';
        }

        if (!nonLoggedList.includes(request.url)) {
            this.loggingService.addUserAction('service', {
                name: request.url,
                body: bodyForLog,
                hasToken: !!localStorage.getItem('Token'),
                'X-Correlation': GUID,
            });
        }

        if (localStorage.getItem('Token') && request.url != summaryUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'bearer ' + localStorage.getItem('Token'),
                },
            });
        }

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                const { status } = err;

                this.loggingService.addUserAction('service error', {
                    status,
                    body: err?.error ? err.error : {},
                    url: request.url,
                    'X-Correlation': GUID,
                });

                if (status === 401) {
                    this.store.dispatch(UserLoggedOutInitAction());
                }

                return throwError(err);
            })
        );
    }
}
