import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
    selector: 'app-raised-button',
    templateUrl: '../base-button/base-button.component.html',
})
export class RaisedButtonComponent extends BaseButtonComponent {
    @Input() isRaisedButton = true;
}
