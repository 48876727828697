import { ActionReducer, INIT, MetaReducer } from '@ngrx/store';
import { AppActions } from '@store/actions/app.actions';
import { merge } from 'lodash';
import { AppState } from '@store/models/app.model';
import { AuthenticationActions } from '@store/actions/authentication.actions';

export const storageKey = '__app_storage__';

const saveAppState = (nextState: Partial<AppState>): void => {
    localStorage.setItem(storageKey, JSON.stringify(nextState));
};

const getSavedState: Partial<AppState> = JSON.parse(localStorage.getItem(storageKey));

export function storage(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    let initialLoad = true;

    return function (state, action) {
        const nextState: AppState = reducer(state, action);
        nextState.authentication?.username && saveAppState(nextState);

        // force a logout if store is not located
        if (action?.type === AppActions.STORE_INIT) {
            const hasStore = storageKey in localStorage;
            !hasStore && localStorage.clear();
            !hasStore && localStorage.setItem(storageKey, '{}');
        }

        if (action?.type === AuthenticationActions.USER_LOGOUT_SUCCESS) {
            const setPreferencesState = reducer(
                {
                    authentication: undefined,
                    container: {
                        userPreferences: nextState?.container?.userPreferences,
                    },
                    experimentalFeatures: nextState?.experimentalFeatures,
                },
                { type: INIT }
            );
            saveAppState(setPreferencesState);
            return setPreferencesState;
        }

        if (initialLoad) {
            initialLoad = false;
            return merge(nextState, getSavedState) as AppState;
        }

        return nextState;
    };
}

export const storageReducer: MetaReducer<AppState>[] = [storage];
