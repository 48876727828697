import { Component } from '@angular/core';
import { SecurityService } from '../../../services/security/security.service';
import { take } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SsoResponseEnum } from '../authentication/auth.model'; 

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
    public errorMessage: string;
    public forgotPasswordForm = new FormGroup({
        username: new FormControl('', [Validators.required, Validators.email]),
    });
    public successMessage: string;

    constructor(private readonly securityService: SecurityService) {}

    public request(): void {
        const { username } = this.forgotPasswordForm.value;

        this.securityService
            .requestForgotPassword({ username })
            .pipe(take(1))
            .subscribe(
                (res: boolean) => {
                    if (res) {
                        this.successMessage = `We've sent you a link via email to create a new password. This link expires after 24 hours`;
                        this.errorMessage = undefined;
                    } else {
                        this.errorMessage =
                            'Something went wrong. We were unable to send you a new password. Please check your email address and try again.';
                        this.successMessage = undefined;
                    }
                },
                (res: HttpErrorResponse) => {
                    if (res.status === 400 && res.error === SsoResponseEnum.SsoUserError) {
                        this.errorMessage =
                            'Your user is part of an enterprise identity provider. To reset your password, please contact with your security administrator.';
                    } else {
                        this.errorMessage =
                            'Something went wrong. We were unable to send you a new password. Please check your email address and try again';
                    }
                    this.successMessage = undefined;
                }
            );
    }
}
