<div class="login_screen_holder">
    <section class="animated fadeIn w-80 m-auto">
        <mat-card>
            <div class="login-image--small">
                <img src="img/omnia_logo.png" />
            </div>
            <h6>No portal found</h6>
            <p>
                It appears you do not have any assigned portals to your account.
            </p>
            <p>
                Please contact your organisation administrator to add a portal to your account.
            </p>

            <p>
                You will be redirected to the login page shortly.
            </p>
        </mat-card>
    </section>
</div>
