import { Component } from '@angular/core';
import { SecurityService } from '@services/security/security.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@services/toast/toast.service';
import { ForgotPasswordUpdateModel } from '@models/api/forgot-password-update.model';
import { UsernameModel } from '@models/api/username.model';

@Component({
    selector: 'app-forgot-password-update',
    templateUrl: './forgot-password-update.component.html',
})
export class ForgotPasswordUpdateComponent {
    public request: ForgotPasswordUpdateModel;
    public confirmPassword: string;
    public errorMessage: string;
    public successMessage: string;

    public referenceValidation: {
        message?: string;
        isValid?: boolean;
    };

    public readonly forgotPasswordUpdate = new FormGroup({
        newPassword: new FormControl(null, [Validators.required, Validators.minLength(12)]),
        confirmPassword: new FormControl(null, [Validators.required, Validators.minLength(12)]),
    });

    constructor(
        private readonly toaster: ToastService,
        private readonly securityService: SecurityService,
        private readonly authenticationService: AuthenticationService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute
    ) {
        this.request = new ForgotPasswordUpdateModel();

        const { code } = this.activatedRoute.snapshot.params;

        this.request.referenceId = code;

        if (code) {
            this.validateReferenceId();
        }
    }

    public updatePassword(): void {
        const { newPassword, confirmPassword } = this.forgotPasswordUpdate.value;

        this.request.newPassword = newPassword;

        if (newPassword !== confirmPassword) {
            this.errorMessage = 'Password and confirm password should match';
            return;
        }

        this.authenticationService.clearLocalStorage();
        this.securityService
            .updateForgotPassword(this.request)
            .pipe(take(1))
            .subscribe(
                (res: boolean) => {
                    if (res) {
                        this.successMessage = 'Your password has been updated';
                        this.errorMessage = undefined;
                        this.redirect();
                    } else {
                        this.errorMessage = 'Unable to update password.';
                        this.successMessage = undefined;
                    }
                },
                () => {
                    this.errorMessage = 'Unable to update password.';
                    this.successMessage = undefined;
                }
            );
    }

    private redirect(): void {
        // add to effects
        this.toaster.notify('Success! You will be redirected to the login in 5 seconds', 7000);
        void this.router.navigateByUrl('dashboard');
    }

    private validateReferenceId(): void {
        this.securityService.validateForgotPasswordReferenceId(this.request.referenceId).subscribe(
            (res: UsernameModel) => {
                // Used timeout here just to make UI a bit smoother
                setTimeout(() => {
                    this.referenceValidation = {
                        isValid: true,
                        message: '',
                    };
                }, 500);
                this.request.username = res.username;
            },
            (error: HttpErrorResponse) => {
                if (error.status === 404 || error.status === 400) {
                    this.errorMessage =
                        'Password update request is invalid, please contact your administrator.';
                } else {
                    this.errorMessage = 'Something went wrong, please try again';
                }
                this.successMessage = undefined;
            }
        );
    }
}
