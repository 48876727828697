import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusInterface } from '@features/status/model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

interface indicatorInterface {
    indicator: string;
}

@Injectable({
    providedIn: 'root',
})
export class HealthCheckService {
    constructor(private readonly http: HttpClient) {}

    public getStatusToastClassName = ({ indicator }: indicatorInterface): string => {
        const baseClass = 'status-toast';
        switch (indicator) {
            case 'none':
                return `${baseClass} none-toast`;
            case 'major':
                return `${baseClass} major-toast`;
            case 'critical':
                return `${baseClass} major-toast`;
            case 'partial':
                return `${baseClass} partial-toast`;
            case 'minor':
                return `${baseClass} minor-toast`;
            default:
                return `${baseClass} none-toast`;
        }
    };

    public getStatusText = ({ indicator }: indicatorInterface): string => {
        switch (indicator) {
            case 'none':
                return '';
            case 'major':
                return 'We are experiencing performance issues.';
            case 'critical':
                return 'We are experiencing performance issues.';
            case 'partial':
                return 'We are experiencing performance issues on parts of the system.';
            case 'minor':
                return 'We are experiencing some performance issues.';
            default:
                return '';
        }
    };

    // Fix types
    public getStatus(): Observable<StatusInterface> {
        return this.http.get<StatusInterface>(environment.statusIOEndpoint).pipe(share());
    }
}
