export enum Permissions {
    insights = 'Insights',
    status = 'Status',
    dashboard = 'Dashboard',
    channels = 'Channels',
    connect = 'Connect',
    marketing = 'Marketing',
    settings = 'Settings',
    pricing = 'Pricing',
    overview = 'ProductOverview',
    reports = 'Reports',
    performance = 'Performance',
    strategy = 'PricingStrategy',
    export = 'PricewatchExport',
}
